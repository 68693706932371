.main-admin{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 17px;
}
.invalid-feedback{
    font-weight: 500 !important;
}
.form-label{
    font-weight: 600 !important;
}

.modal-header{
    background: #6c7ae0 !important;
    color: white !important;
}

.btn-submit{
    background: #6c7ae0;
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 89px;
    height: 37px;
    
}
.btns{
  
    border-radius: 6px;
    text-decoration: none;
    width: 97px;
    height: 36px;
    display: flex;
    justify-content: center;
}

.modal-header .btn-close{
    border: 1px solid;
    border-radius: 25px;
    color: black;
    outline: none;
}

